import React from "react";
import Layout from "../../components/Layout/Layout";
import Brochure from "../../components/Markup/Brochure";
import SEO from "../../components/seo";

import copy from "../../../locales/bur/brochure.json";
import { graphql, useStaticQuery } from "gatsby";
const Burmese = () => {
  const data = useStaticQuery(graphql`
    query {
      brochure: file(relativePath: { eq: "bur/Brochure.pdf" }) {
        publicURL
      }
      preview: file(relativePath: { eq: "bur/brochure-preview.png" }) {
        ...DesktopImage
      }
    }
  `);
  return (
    <Layout>
      <SEO name="resources" />
      <Brochure
        language="Burmese"
        copy={copy}
        previewImage={data.preview}
        brochureURL={data.brochure.publicURL}
        languageCode="bur"
      />
    </Layout>
  );
};

export default Burmese;
